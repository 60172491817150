.Voucher--grid__container {
  width: 100%;
}
@media screen and (max-width: 47.99609375rem) {
  .Voucher--grid__container {
    padding-bottom: 80px;
    margin-top: 0px;
  }
}
.Voucher--grid__container_heading {
  width: 100%;
  margin-top: 2.5rem;
}
@media screen and (min-width: 48rem) {
  .Voucher--grid__container_heading {
    margin-left: 8.33%;
    width: 66.66%;
  }
}
@media screen and (max-width: 47.99609375rem) {
  .Voucher--grid__container_heading {
    margin-top: 2rem;
  }
}
.Voucher--grid__container_error_container {
  width: 100%;
  margin-bottom: 21px;
}
@media screen and (min-width: 48rem) {
  .Voucher--grid__container_error_container {
    margin-left: 8.33%;
    width: 37.5%;
  }
}
@media screen and (min-width: 48rem) and (max-width: 63.99609375rem) {
  .Voucher--grid__container_error_container {
    width: 100%;
  }
}
@media screen and (min-width: 48rem) and (max-width: 63.99609375rem) and (min-width: 48rem) {
  .Voucher--grid__container_error_container {
    margin-left: 8.33%;
    width: 50%;
  }
}
.Voucher--grid__container_error_container .mt-alert {
  width: 100% !important;
}
.Voucher--grid__container_form_container {
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .Voucher--grid__container_form_container {
    margin-left: 8.33%;
    width: 37.5%;
  }
}
@media screen and (min-width: 48rem) and (max-width: 63.99609375rem) {
  .Voucher--grid__container_form_container {
    width: 100%;
  }
}
@media screen and (min-width: 48rem) and (max-width: 63.99609375rem) and (min-width: 48rem) {
  .Voucher--grid__container_form_container {
    margin-left: 8.33%;
    width: 50%;
  }
}
.Voucher--grid__container_input_container {
  width: 100%;
}
.Voucher--grid__container_input_container .voucher-text-field-container {
  margin-bottom: 32px;
}
.Voucher--grid__container_image {
  width: 100%;
  margin-top: -76px;
}
@media screen and (min-width: 48rem) {
  .Voucher--grid__container_image {
    margin-left: 16.66%;
    width: 25%;
  }
}
@media screen and (max-width: 47.99609375rem) {
  .Voucher--grid__container_image {
    display: none;
  }
}
@media screen and (min-width: 48rem) and (max-width: 63.99609375rem) {
  .Voucher--grid__container_image {
    width: 100%;
  }
}
@media screen and (min-width: 48rem) and (max-width: 63.99609375rem) and (min-width: 48rem) {
  .Voucher--grid__container_image {
    margin-left: 16.66%;
    width: 25%;
  }
}
.Voucher--grid__container_image_mobile {
  width: 100%;
  display: none;
}
@media screen and (max-width: 47.99609375rem) {
  .Voucher--grid__container_image_mobile {
    display: block;
  }
}

.Voucher_example {
  margin-bottom: 30px;
}
.Voucher_submit_btn {
  width: 100% !important;
}
.Voucher_error_message {
  width: 90%;
  color: #BB2914;
  line-height: 21px;
  padding-left: 9px;
  vertical-align: text-bottom;
  display: inline-block !important;
}
.Voucher_product_illustration {
  margin-left: 100px;
}

@media screen and (min-width: 48rem) {
  .Voucher--grid__container_image > .mt-illustration {
    width: 19.4375rem;
  }
}
@media screen and (max-width: 48rem) {
  .Voucher_product_illustration {
    display: none;
  }
}

