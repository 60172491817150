@use "../../node_modules/@able/web/src/vars" as vars;
@use "../../node_modules/@able/web/src/foundations/grid" as grid;
@use "../../node_modules/@able/web/src/foundations/spacing" as spacing;
@use "../../node_modules/@able/web/src/mixins" as mixins;

.Payment{
    &--grid{
        &-container {
            @include grid.breakpointColumns(12);

            &__topcontent {
                @include grid.breakpointColumns(12, (offset:1, cols: 7));
                @include mixins.mq-alias("viewportXS") {
                    margin-top: 0px !important;
                }
            }
            &__maincontent {
                margin-bottom: spacing.spacing(.5);
                order: 1;

                @include mixins.mq-alias("viewportXS") {
                    order: 2;
                }
            }
            &__summary {
                margin-bottom: spacing.spacing(.5);
                padding-top: 22px;
                order: 2;

                @include mixins.mq-alias("viewportXS") {
                    order: 1;
                    margin-left: 0% !important;
                }
            }
            &__tabcontainer {
                @include grid.breakpointColumns(12);
                .tabs-container {
                    margin-bottom: spacing.spacing(7);
                    @include mixins.mq-alias("viewportXS") {
                        margin-bottom: spacing.spacing(5);
                    }
                }
            }
        }
        &_iFrameContainer iframe{
            width: 98% !important;
            border: none;
            height: 800px;
            @include mixins.mq-alias("viewportXS") {
                width: 100% !important;
            }
        }
    }
    &-hide-braintree-iframe{
        display: none;
    }
    &-type-heading{
        margin-bottom: spacing.spacing(4);
    }
    &PannelLegacy{
        margin-top: 12px;
    }
    &TopPageErrorAlignment {
        margin-bottom: spacing.spacing(4);
    }
}

#payment {
    &-root-content {
        .tabs-container {
            .mt-tabs--relative-width {
                .mt-el__item {
                    width: 50% !important;
                }
            }
        }
        legend {
            border-bottom: 0;
        }
    }
}
html {
    overflow-y: scroll;
}
