@use "../../node_modules/@able/web/src/vars" as vars;
@use "../../node_modules/@able/web/src/foundations/grid" as grid;
@use "../../node_modules/@able/web/src/foundations/spacing" as spacing;
@use "../../node_modules/@able/web/src/patterns/InlineLinks" as InlineLinks;
@use "../../node_modules/@able/web/src/mixins" as mixins;
@use '../../node_modules/@able/web/src/foundations/spacing/index' as able;

.ErrorPage--grid {
    &-container {
        @include grid.breakpointColumns(12);

        &__maincontent {
            @include grid.breakpointColumns(12, (offset: 4, cols: 4));
            margin-bottom: spacing.spacing(.5);
        }
    }
}

.ErrorPage-container {
    margin-top: spacing.spacing(10);
    font-family: "Telstra Akkurat", Arial;
    font-weight: 300;
}

.ErrorPage-submit {
    height: 48px;
    width: 290px;
    background-color: #1964C8;
    margin: 40px auto 0;
    padding-top: 7px;
    padding-right: 45px;
    padding-bottom: 7px;
}

.ErrorPage-submit:after {
    right: 1px;
}

.TopPageError-main-container .mt-alert > :last-child {
    margin-left: 0 !important;
}

.TopPageError-main-container .mt-alert.mt-card .mt-alert__icon {
    top: 1.1rem !important;
}

.TopPageError-main-container h2 {
    margin-bottom: 0 !important;
}

@media screen and (max-width: 767px) {

    .ErrorPage-container {
        margin-top: spacing.spacing(4);
    }

    .ErrorPage-error-banner {
        line-height: 40px;
    }

    .ErrorPage-submit {
        background-color: #1964C8;
        max-width: 375px;
    }

    .ErrorPage-submit:after {
        right: 1px;
    }

    .ErrorPage-oval {
        background-image: url('./icon_big_error.svg');
        background-repeat: no-repeat;
        height: 100px;
        margin-bottom: 0px;
    }

    .ErrorPage-error-message {
        height:auto;
    }
}

@media screen and (min-width: 768px) {
    #errorpage-btn{
        font-size: 16px !important;
        width: 50% !important;
        padding: 20px !important;
        align-self: center;
    }
}

.ErrorChatSvg{
    width: 300px !important;
}

.Error-Page-Content{
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 22px;
}

@media screen and (min-width: 48rem){
    .ErrorPage--grid-container__maincontent-width {
        width: 50%!important;
    }
}
