.ErrorPage--grid-container {
  width: 100%;
}
.ErrorPage--grid-container__maincontent {
  width: 100%;
  margin-bottom: 0.25rem;
}
@media screen and (min-width: 48rem) {
  .ErrorPage--grid-container__maincontent {
    margin-left: 33.33%;
    width: 33.33%;
  }
}

.ErrorPage-container {
  margin-top: 5rem;
  font-family: "Telstra Akkurat", Arial;
  font-weight: 300;
}

.ErrorPage-submit {
  height: 48px;
  width: 290px;
  background-color: #1964C8;
  margin: 40px auto 0;
  padding-top: 7px;
  padding-right: 45px;
  padding-bottom: 7px;
}

.ErrorPage-submit:after {
  right: 1px;
}

.TopPageError-main-container .mt-alert > :last-child {
  margin-left: 0 !important;
}

.TopPageError-main-container .mt-alert.mt-card .mt-alert__icon {
  top: 1.1rem !important;
}

.TopPageError-main-container h2 {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 767px) {
  .ErrorPage-container {
    margin-top: 2rem;
  }
  .ErrorPage-error-banner {
    line-height: 40px;
  }
  .ErrorPage-submit {
    background-color: #1964C8;
    max-width: 375px;
  }
  .ErrorPage-submit:after {
    right: 1px;
  }
  .ErrorPage-oval {
    background-image: url("./icon_big_error.svg");
    background-repeat: no-repeat;
    height: 100px;
    margin-bottom: 0px;
  }
  .ErrorPage-error-message {
    height: auto;
  }
}
@media screen and (min-width: 768px) {
  #errorpage-btn {
    font-size: 16px !important;
    width: 50% !important;
    padding: 20px !important;
    align-self: center;
  }
}
.ErrorChatSvg {
  width: 300px !important;
}

.Error-Page-Content {
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 22px;
}

@media screen and (min-width: 48rem) {
  .ErrorPage--grid-container__maincontent-width {
    width: 50% !important;
  }
}

