.telstra-footer {
  flex-shrink: 0;
  width: 100%;
  background-color: #F4F4F4;
  box-sizing: border-box;
  margin-top: 7.5rem;
}

.telstra-footer ul, .telstra-footer li {
  list-style-type: none;
}

.telstra-footer li {
  padding-left: 40px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 102px;
}

@media only screen and (min-width: 768px) {
  .footer-first-three li:first-child {
    padding-left: 0 !important;
  }
}
.footer-container a {
  color: #414141;
  font-family: "Telstra Akkurat", Arial;
  font-size: 14px;
  letter-spacing: 0.18px;
  line-height: 17px;
  text-decoration: none;
  display: inline-block;
  min-height: 44px;
  min-width: 44px;
  padding-top: 12px;
  padding-bottom: 10px;
}

.footer-first-three, .footer-last-two {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
}

@media only screen and (max-width: 1023px) {
  .footer-container {
    padding: 10px 56px;
  }
}
@media only screen and (max-width: 767px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    /* margin: 0 auto; */
    padding: 2.5rem 0;
  }
  .footer-first-three {
    display: flex;
    flex-direction: column;
  }
  .footer-last-two {
    display: flex;
    flex-direction: column;
  }
  .footer-container a {
    padding-left: 0;
    padding-top: 16px;
  }
  .footer-container-three a:first-child {
    padding-top: 0 !important;
  }
}

