.global-message.mt-sheet--visible .mt-sheet__panel {
  left: inherit;
  top: 0;
  width: 31rem !important;
  height: inherit !important;
}

.global-message.mt-sheet .mt-sheet__close[type=button] {
  display: block !important;
}

.global-message-container .able-MessageGlobal--Attention {
  width: 100%;
  z-index: 1;
  background-color: #ECF6FE;
}

.able-message-global--collapsed + .mt-global-message__header-push {
  display: none;
}

.mt-global-message__header-push {
  height: 55px;
  transition: height 0.2s ease-in;
}

.global-message-container .able-MessageGlobal--Attention a:visited {
  color: #1964c8;
}

@media only screen and (max-width: 767px) {
  .global-message.mt-sheet--visible .mt-sheet__panel {
    bottom: 0 !important;
    height: 100% !important;
    width: 100% !important;
    top: 0 !important;
    left: inherit !important;
    margin-right: inherit !important;
    transform: translateY(0) !important;
    overflow: hidden;
    transition: transform 0.5s;
  }
  .mt-global-message__header-push {
    height: 105px;
  }
}
@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .mt-global-message__header-push {
    height: 70px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .mt-global-message__header-push {
    height: 75px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .mt-global-message__header-push {
    height: 75px;
  }
}

