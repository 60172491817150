/* ==========================================================================
 * PRELOADER OVERLAY
 * ========================================================================== */

@keyframes play {
  from { background-position: 0; }
  to { background-position: -2160px; }
}

.preloader-container {
  position: relative;
  width: 100%;
  min-height: 0px !important;
}

.preloader-overlay {
  background-color: rgba(255, 255, 255, 0.932);
  height: 100%;
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 9999;
}

.fullpage-indicator {
  width: 48px;
  height: 48px;
  background: white;
  z-index: 1;
  background: transparent url(loader-blue.png) no-repeat 0 0;
  animation: play 3s steps(45) infinite;
  position: absolute;
  top: 40%;
  left: 50%;
  margin: -24px 0px 0px -24px;
}

.transparent-loading {
  height: 20px;
  color: #333333;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  top: 48%;
  text-align: center;
}

/* ==========================================================================
 * LOADING SPINNER
 * ========================================================================== */

.loading-spinner {
  width: 48px;
  height: 48px;
  background-color: transparent !important;
  background-repeat: no-repeat;
  background-image: url('./loader-blue.png');
  animation: play 3s steps(45) infinite;
  position: absolute;
  left: 50%;
}

.loading-text {
  height: 20px;
  color: #333333;
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  left: 46%;
  padding-top: 65px;
}

.div-width{
  width: auto;
  position: relative;
  margin-top: 20%;
}

@media only screen and (max-width: 767px) {
  .loading-spinner {
    left: 45%;
    margin-top: -90px;
  }

  .loading-text {
    left: 40%;
    margin-top: -95px;
  }

  .div-width{
    margin-top: 23%;
  }

  .transparent-loading {
    text-align: center;
    padding: 0 35px 0 35px;
  }
}

@media only screen and (max-width: 487px) {
  .loading-spinner {
    left: 45%;
    margin-top: -80px;
  }

  .loading-text {
    left: 37%;
    margin-top: -86px;
  }

  .div-width{
    margin-top: 24.5%;
  }
}

@media only screen and (max-width: 360px) {
  .div-width{
    margin-top: 28%;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .div-width{
    margin-top: 35%;
  }
}
