@use "../../../node_modules/@able/web/src/mixins" as mixins;

.info-banner {
  max-width: 100% !important;
}
.sheet-button-container {
  text-align: right;
}
.offer-info-banner div button::after {
  background-color: inherit !important;
}
table {
border-radius: 4px;
border: 1px solid rgb(210, 210, 210);
width: 459px;
}
th {
  background: rgb(244, 244, 244);
}
th,td {
  border-bottom: 1px solid #c3c3c3;
}
th,td {
  padding: 15px;
}
.able-Grid--padding--false.offer-banner-grid {
  width: 100%;
}

@media screen and (min-width: 48rem){
  .ChooseAmount--grid-container .offer-banner-grid .info-banner.offer-info-banner {
    margin-right:  1.5rem;
  }
}

