@use "../../node_modules/@able/web/src/vars" as vars;
@use "../../node_modules/@able/web/src/foundations/grid" as grid;
@use "../../node_modules/@able/web/src/foundations/spacing" as spacing;
@use "../../node_modules/@able/web/src/mixins" as mixins;
@use "../../node_modules/@able/web/src/foundations/colour" as colour;

.ChooseAmount--grid {
    &-container {
        @include grid.breakpointColumns(12);
        @include mixins.mq-alias("viewportXS") {
            margin-top: 0;
        }
        &__wrapper {
            @include grid.breakpointColumns(12,(offset:1, cols:10));
            @include mixins.mq-alias("viewportXS") {
                margin-left: 0;
                padding-left: 0;
            }

        }
        &__something-else {
            @include grid.breakpointColumns(12,(offset:1, cols:4));
            @include mixins.mq-alias("viewportXS") {
                margin-left: 0;
                padding-left: 0;
            }

        }
        &__card {
            &:nth-of-type(odd){
                @include grid.breakpointColumns(12,(offset:0, cols: 6));
                padding-bottom: 24px;
            }
            &:nth-of-type(even){
                @include grid.breakpointColumns(12,(offset:0, cols: 6));
                padding-bottom: 24px;
            }
            &_promotion_details{
                position: absolute !important;
                top: -15px;
                left: 19px;
                font-family: "Telstra Akkurat", Arial !important;
                font-size: 14px !important;
                font-weight: bold !important;
                letter-spacing: -0.02px !important;
                line-height: 18px !important;
                text-align: center;

                @include mixins.mq-alias("viewportLG") {
                    top: -18px;
                }
            }
        }
        &__initial_container{
            @include grid.breakpointColumns(12,(offset:0, cols: 4.5));
            .tabs-container {
                margin-top: 32px;
                margin-bottom: 25px;
            }
        }
        &__heading{
            @include grid.breakpointColumns(12,(offset:0, cols:10));
            &_content{
                @include grid.breakpointColumns(12,(offset:0, cols:12));
                background-color: #F4F4F4;
                padding: 15px 0 15.5px 15px;
                margin-bottom: 32px;
                margin-top: 8px;
            }
        }
        &__image_container{
            @include grid.breakpointColumns(0,(offset:0, cols: 5));
        }
        &__denomination_heading{
            @include grid.breakpointColumns((offset:0, cols: 9));
            color: #282828;
            font-family: "Telstra Akkurat", Arial;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 28px;
            padding-top: 26px;
            margin-left: 20px;
        }
        &__denomination_price{
            @include grid.breakpointColumns((offset:0, cols: 3));
            width: auto;
            color: #282828;
            font-family: "Telstra Akkurat", Arial;
            font-size: 32px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 41.6px;
            padding-top: 16px;
            flex: 1;
        }
    }
}

.ChooseAmount {

    &__border_margin{
        position: relative;
        border: 1px solid #BDBDBD;
        border-radius: 3px !important;
        display: flex;
        flex-direction: column;
        padding-top: 12px;

        &_promotion{
            border-top: 5px solid #a0148c;
        }
    }

    &__price-value {
        line-height: 41.6px;
        padding-top: 16px;
    }

    &__price-value-with-headline {
        margin-right: 20px;
        text-align: right;
    }

    &__price-value-without-headline {
        text-align: center;
    }

    &__heading-value {
        line-height: 28px;
        padding-top: 21px;
        margin-left: 20px;
        // float: left;
    }

    &__div-summary {
        line-height: 24px;
        padding-top: 20px;
        float: left;
        margin-left: 20px;
        margin-right:15px;
        p {
            color: #282828;
            font-weight: 400;
        }
    }

    &__div_product {
        line-height: 21px;
        display: inline;
        p {
            color: #757575;
            font-size: 14px;
            letter-spacing: 0.18px;
            line-height: 21px;
            margin-right: 15px;
        }
    }

    &__div-fineprint {
        margin-top: 8px;
        margin-bottom: 15px;
    }

    &__div-value,
    &__div-hasValue-fineprint {
        margin-left: 20px;
        margin-top: 8px;
    }

    &__div-empty-fineprint {
        height: 21px;
    }

    &__div-able-btn{
        width: 100% !important;
        padding-top: 20px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    &__able-action-btn{
        width: 100% !important;
    }

    &__div-whats-included .mt-showhide__button, &__div-whats-included .ChooseAmount-ShowHide-Button-Custom button {
        color: #414141 !important;
        font-family: "Telstra Akkurat", Arial;
        font-size: 14px !important;
        letter-spacing: 0.18px;
        font-weight: normal !important;
        margin-left: 20px !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    &__div-whats-included .ChooseAmount-ShowHide-Button-Custom button {
        color: #414141 !important;
        font-family: "Telstra Akkurat", Arial;
        font-size: 14px !important;
        letter-spacing: 0.18px;
        font-weight: normal !important;
        margin-left: 0px !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    &__div-whats-included .mt-showhide__button__icon {
        top: -8px !important;
    }

    &__div-whats-included .mt-showhide {
        margin-left: 0;
    }

    &__div-whats-included .mt-showhide__content__inner {
        margin-top: 0 !important;
        padding-bottom: 30px !important;
        background-color: #F0F6FD;
        border-top: 1px solid #BDBDBD;
    }

    &__div-denomination-details {
        padding: 5% 5% 0 5%;
    }

    .ChooseAmount-ShowHide-Button-Custom &__div-denomination-details {
        padding: 5% 5% 0 0%;
    }

    &__div-denomination-content {
        line-height: 21px;
        display: flex;
        flex-direction: row;
        p {
            color: #414141;
        }
    }

    &__denomination-heading {
        font-size: 14px;
        font-weight: bold;
        padding-left: 5px;
        padding-bottom: 5px;
    }

    &__denomination-content {
        padding-left: 5px;
    }

    &__svg-tick path, &__svg-tick use,
    &__div-whats-included .ChooseAmount-ShowHide-Button-Custom button svg use {
        fill: #0062DA !important;
    }

    &__card_margin{
        margin-top:41px;
    }
}

.Category {
    &__empty-container {
        margin-top: 32px;
    }
}

.SubCategory {
    &__palette {
        @include grid.breakpointColumns(12,(offset:0, cols: 12));
        ul {
            display: flex;
            flex-flow: wrap;
            padding-left: 0;
            list-style-type: none;
        }
    }

    &__btn-style {
        border: 1px solid #0064D2;
        border-radius: 24px;
        height: 30px;
        text-align: center;
        padding: 10px 15px 4px 15px;
        position: relative;
        margin-right: 12px;
        cursor: pointer;
        min-width: 44px;
        min-height: 44px;

        p {
            color:#0064D2;
        }
    }

    &__input-style {
        position: absolute !important;
        top: -9999px !important;
        left: -9999px !important;
    }

    &__btn-style:hover,
    &__btn-selected {
        background-color: #0064D2;
        p,
        p:hover {
            color: #FFF;
        }
    }
}
#ChooseAmount-title-container{
    @include mixins.mq-alias("viewportXS") {
        margin-top: 0px;
    }
}

.ChooseAmount__price-value-with-headline {
    margin-right: 20px;
    text-align: right;
    // width: 50%;
    float: right;
}

.Voucher-chooseamount-info {
    margin-top: 24px;
    margin-bottom: 40px;
}

.voucher-info-text {
    height: 44px;
    width: 458px;
    color: #3B3B3B;
    font-family: "Telstra Akkurat";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    @include mixins.mq-alias("viewportXS") {
        width: auto;
    }
}

.pricevalue-beforediscount {
    color: #757575;
    font-family: "Telstra Akkurat";
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 28px;
    font-style: normal;
    font-weight: normal;
}

.mt-showhide__content p a.insternational-calling {
    background-color: transparent;
}
.country-name {
    line-height: 35px;
}
.country__close__button {
    width: 100%;
}
.able-ModalContent--Comfortable footer {
    background-color: transparent;
}
body {
    overflow-x: hidden!important;
}
.showhide__content_remove_accordian {
    margin-left: 20px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.showhide__content_remove_accordian p a {
    transition: color 250ms,border 250ms,box-shadow 250ms,text-shadow 250ms,background-color 250ms;
    text-decoration: none;
    color: #0064d2;
    background-color: #fff;
    font-weight: 400;
    border-bottom: 0.0625rem solid
}
.zone-data-ModalContent.able-ModalContent--Comfortable.able-Modal_isScrolling header {
    min-height: 45px;
}
p.country-name {
    font-weight: 400;
}
.ChooseAmount-ShowHide-Button-Custom .accordion--expanded > div {
    margin-top: 0 !important;
    padding-bottom: 30px !important;
    background-color: #F0F6FD;
    border-top: 1px solid #BDBDBD;
}

.ChooseAmount-ShowHide-Button-Custom.able-Accordion--compact>div.accordion--expanded>div {
    box-shadow: none;
}
.ChooseAmount__div-whats-included .ChooseAmount-ShowHide-Button-Custom button:hover {
    box-shadow: none;
    background-color: transparent;
}
