.ViewBalance {
  padding-top: 24px;

  &__icon {
    background: url('./Telstra56.svg') no-repeat;
    min-height: 56px;
  }

  &__title {
    margin-top: 16px;
    margin-bottom: 12px;
  }
}