@use '..' as able;

@each $name in able.$Surface-aliases {
  .able-Surface--#{$name} {
    @include able.Surface($name);
  }

  @each $corner-radius-alias, $size in able.$cornerRadius-sizes {
    @if $name != SurfaceFlat and $corner-radius-alias != cornerRadiusNone {
      .able-Surface--#{$name}--#{$corner-radius-alias}--materialBase {
        @include able.Surface($name, $corner-radius: $corner-radius-alias);
      }
    }
    @if $name == SurfaceFlat {
      @each $background in able.$Surface-backgrounds {
        .able-Surface--#{$name}--#{$corner-radius-alias}--#{$background} {
          @include able.Surface($name, $corner-radius: $corner-radius-alias, $background: $background );
        }
      }
    }
  }

  @if $name != SurfaceTop and $name != SurfaceFlat {
    .able-Surface--#{$name}--interactive {
      @include able.Surface($name, true);
    }

    @each $corner-radius-alias, $size in able.$cornerRadius-sizes {
       @if $corner-radius-alias != cornerRadiusNone {
         .able-Surface--#{$name}--#{$corner-radius-alias}--interactive {
           @include able.Surface($name, true, $corner-radius: $corner-radius-alias);
         }
       }
    }
  }
}

