.cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9, .cls-10 {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.cls-1, .cls-2, .cls-3, .cls-5, .cls-6, .cls-8, .cls-9, .cls-10 {
  stroke-width: 2px;
}

.cls-1, .cls-4, .cls-5, .cls-6, .cls-7 {
  stroke: #0d54ff;
}

.cls-1, .cls-11, .cls-12 {
  fill: #b2e2fb;
}

.cls-2, .cls-9, .cls-10 {
  stroke: #c8c8c8;
}

.cls-2, .cls-13 {
  fill: #f4f4f4;
}

.cls-3, .cls-6, .cls-7, .cls-8, .cls-10 {
  fill: none;
}

.cls-3, .cls-8 {
  stroke: #f96449;
}

.cls-4 {
  stroke-width: 2px;
}

.cls-4, .cls-5, .cls-9, .cls-14 {
  fill: #fff;
}

.cls-7 {
  stroke-width: 2px;
}

.cls-8 {
  stroke-dasharray: 0 0 0 0 0 0 2 4 2 4 2 4;
}

.cls-13, .cls-11, .cls-15, .cls-16, .cls-17, .cls-12, .cls-14, .cls-18 {
  stroke-width: 0px;
}

.cls-11, .cls-16, .cls-18 {
  fill-rule: evenodd;
}

.cls-15 {
  fill: #f96449;
}

.cls-16 {
  fill: #ffddd1;
}

.cls-17, .cls-18 {
  fill: #0d54ff;
}

