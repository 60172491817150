.AutoRecharge-container {
  display: flex;
  flex-direction: row;
}
.AutoRecharge-incentive-container, .AutoRecharge-icon-container {
  display: flex;
  flex-direction: row;
}

.AutoRecharge-Inactive.able-icon use, .AutoRecharge-Inactive.able-icon path {
  fill: rgb(160, 20, 140);
}

.AutoRecharge-Active.able-icon use, .AutoRecharge-Active.able-icon path {
  fill: rgb(0, 138, 0);
}

