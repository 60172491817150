.modal {
    background-color: rgba(255,255,255,0.1) !important;    
}
.vertical-alignment-helper {
    display:table;
    height: 100%;
    width: 100%;
}
.vertical-align-center {    
    display: table-cell;
    vertical-align: middle;
}
.modal-content {    
    width:620px;
    height:inherit;    
    margin: 0 auto;
}

.modal-header {
    border-bottom: 0 !important;
}

.modal-window-footer {
    padding-top: 0 !important;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.window-popup-text {	
    width: auto;
    color: #333333;
    line-height: 17px;
    text-align: center;
}

.session-expired-card-title {	
    height: 40px;	
    width: auto;	
    color: #333333;	
    font-size: 24px;
    line-height: 25px;
    text-align: center;
}
.window-content-space {
    margin-left: 45px;
    margin-right: 25px;

}
.window-body {
    overflow-y: unset;
    margin-right: 20px;
    margin-bottom: 25px;
    text-align: center;
}

.div-margin-top {
    margin-top: 20px;
}

.window-submit {
    margin-top: 30px;
}

.window-link {
    text-align: center;
}

.modal-dialog {
    width: 450px
}
.mt-sheet__panel {
    pointer-events: auto;
}

.disable-pointer-event {
    pointer-events: none;
}

@media only screen and (max-width: 767px) {
    .window-submit {
        margin-top: 25px ;
    }
    .modal-content {    
        margin-left: 15px;
        margin-right: 15px;
        width:inherit;
        height:inherit;
    }

    .modal-content-space {        
        margin-left: 0px;
        margin-right: 0px;
    }

    .modal-dialog {
        width: auto;
    }

    .mt-sheet--visible .mt-sheet__panel {
        bottom: 154px !important;
        height: 433px !important;
        width: 360px !important;
        top: 50% !important;
        left: 50% !important;
        margin-right: -50% !important;
        transform: translate(-50%, -50%) !important;
        overflow: hidden;
        }
    .mt-sheet__content{
        padding-bottom: 0;
    }
}

@media only screen and (min-width: 767px) {
    .mt-sheet--visible .mt-sheet__panel {
        left: 27%;
        top: 25%;
        width: 559px !important;
        height: 428px !important;
        }
}

.mt-sheet__toolbar:before {
    display: none !important;
}

.mt-sheet .mt-sheet__close[type=button] {
    left: auto !important;
    right: 0 !important;
    display: none !important;
}
.Sheet-title{
  color: #282828;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 25px;
}
.Sheet-you-can-sign-back-in {
  font-family: "Telstra Akkurat", Arial;
  font-size: 16px;
}

.SheetTimerSvg{
    margin-bottom: 20px;
    @media screen and (min-width: 48rem) {
        width: 19.4375rem !important;
    }
}

.mt-sheet--visible .mt-sheet__backdrop {
    background-color: rgba(0,0,0,0.4);
}
