.modal {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.vertical-alignment-helper {
  display: table;
  height: 100%;
  width: 100%;
}

.vertical-align-center {
  display: table-cell;
  vertical-align: middle;
}

.modal-content {
  height: 294px;
  width: 359px;
  margin: 0 auto;
}

.ModalDeleteFont {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 33.6px;
}

.close {
  background-color: white !important;
  border: 0;
  margin-top: 5% !important;
}

.scc-cross-icon {
  color: rgb(25, 100, 200) !important;
  font-weight: 500;
  border: 0;
}

.td-icon-xs {
  font-size: 27px;
  padding-left: 9px;
}

.modal-header {
  border-bottom: 0 !important;
}

.modal-footer {
  border-top: 0 !important;
  margin-bottom: 20px;
  margin-top: 0 !important;
}

.modal-footer-deletesavedcc {
  padding-top: 0 !important;
}

.error-icon-deletesavedc {
  margin-left: 0 !important;
}

.modal-popup-text {
  width: 267px;
  color: #414141;
  margin-bottom: 15px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 300;
}

.txt-tel-num {
  font-weight: bold;
  padding-left: 30px;
}

.delete-saved-card-title {
  height: 40px;
  width: auto;
  color: #333333;
  font-size: 24px;
  line-height: 36px;
}

.modal-content-space {
  margin-left: 25px;
  margin-right: 25px;
  height: 235px !important;
}

.modal-body {
  overflow-y: unset;
  padding: 15px 0px;
}

.div-margin-top {
  margin-top: 0px;
}

.input-box {
  height: 31px !important;
  width: 90px !important;
  margin-left: 93px !important;
}

.savedCCDeleteBtn {
  align-self: left;
}

.savedCCDeleteBtn.deleteBtn-after.btn.do-business {
  padding-right: 46px !important;
}

.modal-dialog {
  width: 450px;
}

@media only screen and (max-width: 767px) {
  #deleteSavedCardModalId .mt-sheet__close[type=button] {
    left: auto !important;
    right: 0 !important;
    display: inline !important;
    background-color: white;
  }
  #deleteSavedCardModalId .mt-sheet__panel {
    left: 27%;
    top: 25%;
    width: 306px !important;
    height: fit-content !important;
    overflow: hidden;
    border-bottom: 2px solid red;
  }
  .modal-content {
    margin-left: 55px;
    margin-right: 15px;
    height: 289px;
    width: 306px;
  }
  .txt-tel-num {
    text-align: right;
  }
  .modal-content-space {
    margin-left: 0px;
    margin-right: 0px;
  }
  .input-box {
    margin-left: 91px !important;
  }
  .modal-dialog {
    width: auto;
  }
  .col1 {
    -webkit-flex: 50%;
    text-align: left;
  }
  .col2 {
    -webkit-flex: 50%;
    font-weight: bold;
    text-align: right;
  }
  .close {
    margin-top: 0px !important;
  }
  .scc-cross-icon {
    bottom: 10px;
  }
}
@media only screen and (min-width: 767px) {
  .col1 {
    flex: 50%;
    text-align: left;
  }
  .col2 {
    flex: 50%;
    text-align: right;
  }
}
#able-mt-text-field--delete-saved-pin-ie, .deletesaved-pin-ie {
  visibility: hidden;
  display: none;
}

#able-mt-text-field--delete-saved-pin {
  font-family: password-mask-ppr !important;
  visibility: visible;
  display: inline;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #able-mt-text-field--delete-saved-pin-ie, .deletesaved-pin-ie {
    visibility: hidden;
    display: none;
  }
  #able-mt-text-field--delete-saved-pin {
    visibility: visible;
    display: inline;
  }
  .pinSpan {
    display: flex;
    align-items: center;
  }
}
.modal-row {
  display: block;
}

.pinSpan {
  vertical-align: -webkit-baseline-middle;
}

.mobileNumberSpan {
  font-weight: bold;
}

.modal-able-btn {
  float: right !important;
  height: 24px;
  color: #D0021B !important;
  font-family: "Telstra Akkurat", Arial;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 20px;
  padding: 0px !important;
  border: none !important;
  margin-top: 18px;
}

.able-mt-button--low-emphasis svg {
  color: #D0021B !important;
}

.ModalButtonAlgin {
  display: flex;
  float: right;
}

.ModalHr {
  position: absolute;
  width: 359px !important;
  height: 2px; /* or whatever. */
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.deletesavedcc-title {
  margin-bottom: 15px;
}

#deleteSavedCardModalId .mt-sheet__toolbar {
  height: 32px;
}

#deleteSavedCardModalId .mt-sheet__content {
  padding: 0;
}

#deleteSavedCardModalId .mt-sheet__panel {
  height: 316px !important;
}

.scc-cross-icon {
  color: black !important;
}

.ModalAbleBtn {
  float: right;
  color: red !important;
}
.ModalAbleBtn svg:not(.able-icon):not(.able-picto) path {
  fill: red !important;
}

.modal-able-btn-color {
  color: #D0021B !important;
}

.ModalDeleteButton {
  width: 500px !important;
  text-align: start;
  display: flex;
}

.modal-able-btn span {
  width: 500px !important;
  text-align: start !important;
  display: flex !important;
  margin-top: -25px !important;
}

@media only screen and (min-width: 767px) {
  #deleteSavedCardModalId .mt-sheet__panel {
    left: 35%;
    top: 25%;
    width: 359px !important;
    overflow: hidden;
    border-bottom: 2px solid red;
  }
  #deleteSavedCardModalId .mt-sheet__close[type=button] {
    left: auto !important;
    right: 0 !important;
    display: inline !important;
    background-color: white;
  }
}
.mt-sheet .mt-sheet__close[type=button] svg {
  fill: #D0021B;
}

.ModalSpace {
  padding: 0 32px;
}

.ModalLine {
  border-top: 2px solid #E8E8E8;
  margin-top: 40px;
}

.deletesaved-pin #able-mt-text-field__error-text {
  margin-bottom: -40px;
}

.deletesaved-pin-ie #able-mt-text-field__error-text {
  margin-bottom: -40px;
}

