.Refund-Image {
  margin-bottom: 40px;
}

.Refund-Page-Title {
  margin-bottom: 40px;
}

.Refund-Page-Content {
  margin-bottom: 25px;
  width: 459px;
}

.Refund-Page-TryAgain {
  margin-top: 27px;
}

.Refund-Page-NeedHelp {
  margin-top: 60px;
  font-size: 16px;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: #414141;
}

.Refund-page-container {
  margin-top: 95px;
}

.Refund-Chat-Image {
  margin-top: 20px;
  margin-right: 15px;
  display: flex;
}

.RefundChatSvg {
  width: 300px !important;
}

@media (max-width: 767px) {
  .Refund-Page-Content {
    width: auto;
  }
}

