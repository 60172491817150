body, html, #spectrum {
  min-width: 100% !important;
}

html, body {
  height: 100%;
  background-color: white;
}

body {
  font-family: "Telstra Akkurat", Arial;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.main-content-wrapper .container.main-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.container {
  width: 100%;
  margin: 0 !important;
}

#root {
  width: 100%;
}

.global-container {
  max-width: 1012px !important;
  margin: 0 auto !important;
}

.container {
  width: 100%;
  margin: 0 !important;
}

.global-container {
  max-width: 1012px !important;
  margin: 0 auto !important;
}

button {
  font-family: "Telstra Akkurat", Arial;
}

button:active, #root button:disabled, button:active:after, #root button:disabled:after {
  background-color: #0090CC;
}

button:after {
  background-color: #1964c8 !important;
}

#root h2 {
  font-family: "Telstra Akkurat", Arial;
}

.search-telstra-btn, .search-telstra-btn:active, .search-telstra-btn:active:after {
  background-color: transparent !important;
}

.main-content-wrapper {
  flex: 1 0 auto !important;
}

.mt-global-header {
  height: 4rem !important;
}

@font-face {
  font-family: "Telstra Akkurat";
  font-weight: 300;
  font-style: normal;
  src: url("fonts/TelstraAkkurat-Light.eot");
  src: url("fonts/TelstraAkkurat-Light.eot?#iefix") format("embedded-opentype"), url("fonts/TelstraAkkurat-Light.woff2") format("woff2"), url("fonts/TelstraAkkurat-Light.woff") format("woff"), url("fonts/TelstraAkkurat-Light.ttf") format("truetype"), url("fonts/TelstraAkkurat-Light.svg#TelstraAkkurat-Light") format("svg");
}
@font-face {
  font-family: "Telstra Akkurat";
  font-weight: normal;
  font-style: normal;
  src: url("fonts/TelstraAkkurat-Regular.eot");
  src: url("fonts/TelstraAkkurat-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/TelstraAkkurat-Regular.woff2") format("woff2"), url("fonts/TelstraAkkurat-Regular.woff") format("woff"), url("fonts/TelstraAkkurat-Regular.ttf") format("truetype"), url("fonts/TelstraAkkurat-Regular.svg#TelstraAkkurat-Regular") format("svg");
}
@font-face {
  font-family: "Telstra Gravur";
  font-weight: bold;
  font-style: normal;
  src: url("fonts/TelstraGravur-Bold.eot");
  src: url("fonts/TelstraGravur-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/TelstraGravur-Bold.woff2") format("woff2"), url("fonts/TelstraGravur-Bold.woff") format("woff"), url("fonts/TelstraGravur-Bold.ttf") format("truetype"), url("fonts/TelstraGravur-Bold.svg#TelstraGravur-Bold") format("svg");
}
@font-face {
  font-family: "Telstra Akkurat Bold";
  font-weight: bold;
  font-style: normal;
  src: url("fonts/TelstraAkkurat-Bold.ttf");
}
.visibility-hidden {
  display: none !important;
}

#spectrum {
  display: none !important;
}

h1:focus {
  outline: none !important;
  background-color: transparent !important;
}

@media screen and (min-width: 48rem) {
  .able-MessageSection--Information {
    max-width: 50% !important;
  }
}

.minimal-footer-homelink > .col-wrapper a {
  color: #333;
  font-weight: bold;
  font-size: 12px !important;
  font-size: 1.2rem;
}

.links-list a, .footer-links ul a {
  color: #333;
  font-size: 12px !important;
  font-size: 1.2rem;
  vertical-align: top;
}

.able-mt-text-field--tiny input {
  width: 16.66% !important;
}

.able-mt-text-field--short input {
  width: 33.33% !important;
}

.able-mt-text-field--default input {
  width: 50% !important;
}

.able-mt-text-field--long input, .able-TextField--Long input {
  width: 100% !important;
}

header.hide-header {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .main-content-wrapper {
    flex: auto !important;
  }
}
.mt-wrapper-ppr {
  display: block !important;
  width: 100% !important;
  padding: 0 !important;
}

.mt-navlist__link {
  min-width: 44px;
  min-height: 44px;
  padding: 7px 7px 7px 15px !important;
}

.mt-breadcrumbs > :nth-last-child(2) .mt-breadcrumbs__icon {
  top: 25px !important;
}

.mt-global-header__nav__button.mt-button {
  display: block !important;
}

.alignIconAndText {
  display: flex;
  align-items: center;
}

.Voucher--grid__container_form_container .able-Grid--padding--false {
  width: 100%;
}

