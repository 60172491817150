
.tabs-container {
    margin-top: 5px;
    margin-bottom: 25px;
}

.mt-tabs.mt-el.mt-tabs--relative-width {
    margin-left: inherit !important;
    width: auto !important;
}

.mt-tabs.mt-el .mt-button.mt-tabs__button--active {
    background-color: rgba(0,100,210,0.06) !important;
}

.paypal-svg span.mt-el__item:nth-child(2) {
    background: url('../../payment/Paypal.svg');
    background-repeat: no-repeat;
    background-size: 75px 50px;
    background-position: center;
}

.paypal-svg span.mt-el__item:nth-child(2) button {
    font-size: 0px;
    height: 100%;
}

.paypal-svg span.mt-el__item:nth-child(2) button:focus {
    font-size: 0px;
    background: url('../../payment/Paypal.svg');
    background-repeat: no-repeat;
    background-size: 75px 50px;
    background-position: center;
    padding-bottom: 10px;
}

.paypal-svg span.mt-el__item:nth-child(2) button:hover {
    font-size: 0px;
    background: url('../../payment/Paypal.svg');
    background-repeat: no-repeat;
    background-size: 75px 50px;
    background-position: center;
    padding-bottom: 10px;
}


.paypal-svg span.mt-el__item:nth-child(2)

.mt-tabs.mt-el .mt-button.mt-tabs__button:hover {
    z-index:2;
    outline:1px dotted transparent !important;
    background-color: rgba(0,100,210,0.06) !important;
    transition:background-color .5s
} 

.paypal-svg span:nth-child(2) button.mt-tabs__button--active {
    background:rgba(0,100,210,0.06) url('../../payment/Paypal.svg') no-repeat !important;
    background-size: 75px 50px !important;
    background-position: center !important; 
}

.paypal-svg span:nth-child(2) button.mt-tabs__button--active:hover {
    background: rgba(0,100,210,0.06) url('../../payment/Paypal.svg') no-repeat !important;
    background-size: 75px 50px !important;
    background-position: center !important; 
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .paypal-svg span.mt-el__item:nth-child(2),
    .paypal-svg span.mt-el__item:nth-child(2) button:focus,
    .paypal-svg span.mt-el__item:nth-child(2) button:hover,
    .paypal-svg span:nth-child(2) button.mt-tabs__button--active,
    .paypal-svg span:nth-child(2) button.mt-tabs__button--active:hover{
        background-size: 75px 20px !important;
    }

    .paypal-svg span.mt-el__item:nth-child(2) button {
        height: 48px;
    }
}

@media only screen and (min-width: 48rem) {
    .mt-tabs.mt-el.mt-tabs--relative-width .mt-el__item {
        width: 230px !important;
    }
}
@media (max-width: 767px) {
    .tabs-container {
        width: inherit;
    }
}
