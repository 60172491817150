.mt-global-header {
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 10%);
    @media (max-width: 1023px) {
        height: 3rem !important;
    }
    .mt-global-header__logo {
        .mt-logo svg {
            margin: 0;
        }
        margin-left: 5.5%;
        margin-top: 0.8rem;
        @media (max-width: 1023px) {
            margin-left: 0.5rem;   
            margin-top: 0.5rem;
        }
    }
    &__content-push {
        height: 4rem;
        @media (max-width: 1023px) {
            height: 3rem;
        }
    }
    &:before{
        background: linear-gradient(90deg,#a0148c,#ffad6e 30%,#ff7aa3);
    }
    &__nav__button.mt-button {
        display: flex !important;
        width: 89px;
        margin-top: 1.3rem !important;
        @media (max-width: 1023px) {
            margin-right: 0 !important;
            margin-top: 0.9rem !important;
        }
        .mt-button{
            &__icon{
                display: flex;
                transform: none !important;
                transition: none !important;
                right: 4rem;
                top: 0;
                .able-icon {
                    margin-right: 0.1rem;
                    margin-left: 0rem;
                }
            }
            &__content{
                color: #0062DA;
                padding: 0rem 0rem 0.1rem 1.5rem !important;
                line-height: 1.5rem !important;
                &:hover{
                    cursor: pointer;
                }
                &:hover {
                    color: #001e82;
                    + .mt-button__icon use {
                        fill: #001e82;
                    }
                }
            }
        }
    }
    &__secondary-nav-without-children {
        width: 87%;
        @media (max-width: 1023px) {
            width: 100%;
        }
        button {
            height: 2.8rem !important;
            padding: 0 !important;
            &:hover {
                background-color: transparent !important;
                cursor: auto;
            }
            &:focus {
                border: 1px solid #0064D2;
                box-shadow: 0px 0px 0px 1px #0099F8;
                background-color: rgba(0, 100, 210, 0.16) !important;
                .mt-button__content {
                    color: #001e82;
                }
                .mt-button__icon use {
                    fill: #001e82 !important;
                }
            }
        }
    }
}
  
.mt-button--arrow :last-child, .mt-button--external :last-child {
    transition: none !important;
    fill: #0062DA;
    z-index: -1;
  }
  .mt-global-header {
    background-color: #fff;
    display: block;
    height: 3rem;
    margin: 0;
    transition: height .4s,box-shadow .2s;
    width: 100%;
    z-index: 100;
    position: relative;
}

.mt-global-header__secondary-nav-without-children {
    display: flex;
    justify-content: flex-end;
}

.mt-global-header__without-children {
    display: flex;
}

.mt-global-header__secondary-nav-without-children-child {
    display: flex!important;
    margin-top: 1.3rem!important;
    width: 89px;
    text-align: center;
    transition: height .4s;
    white-space: nowrap;
}

.mt-global-header__nav__button.mt-button .mt-button__content,.mt-global-header__signout-link-without-children.mt-global-header__nav__button {
    color: #0062da;
    line-height: 1.5rem!important;
    padding: 0 0 0.1rem 1.5rem!important;
    font: 400 1rem/1.4 Telstra Akkurat,Helvetica Neue,Helvetica,Arial,sans-serif;
    letter-spacing: .009375rem;
    margin: 0;
    transition: line-height .4s;
}
.mt-global-header__secondary-nav-without-children-child{
    position: relative;
}
.mt-global-header__signout-link-without-children.mt-global-header__nav__button .mt-button__icon {
    display: flex;
    right: 4rem;
    top: 0;
    transform: none!important;
    transition: none!important;
    position: absolute;
}

.mt-global-header__signout-link-without-children.mt-global-header__nav__button .mt-button__icon svg use {
    fill: #0062da;
    transition: none!important;
    z-index: -1;
}
@media (max-width: 1023px){
    .mt-global-header__secondary-nav-without-children-child {
        margin-right: 0!important;
        margin-top: 0.9rem!important;
    }
}

.mt-global-header:before {
    background: linear-gradient(90deg,#0064d2 0,#5cd6e0 30%,#5e50bf 70%);
    content: "";
    display: block;
    height: 0.125rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
}

.mt-global-header:before {
    background: #462ff9;
}

.mt-global-header__secondary-nav-without-children-child  {
    margin-right: 0.8rem;
  }

.mt-global-header__secondary-nav-without-children .mt-global-header__signout-link-without-children.mt-global-header__nav__button{
    display: flex!important;
    margin-top: 0.7rem !important;
    width: 89px;
    text-align: center;
    transition: height .4s;
    white-space: nowrap;
    position: relative;
    padding: 0px!important;
    margin-right: 12px;
    cursor: pointer;
}

.mt-global-header__secondary-nav-without-children .mt-global-header__signout-link-without-children.mt-global-header__nav__button:hover{
    color: #001e82;
}

@media (max-width: 1023px){
    .mt-global-header__secondary-nav-without-children .mt-global-header__signout-link-without-children.mt-global-header__nav__button{
        margin-top: 0.2rem !important;
    }
}
