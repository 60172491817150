.TPlusSection {
  &__icon {
    background: url('./Rewards104.svg') no-repeat;
    height: 100px;
    width: 144px;
  }
  &__icon2 {
    background: url('./telstraplus-wallet.svg') no-repeat;
    height: 100px;
    width: 144px;
  }
}

