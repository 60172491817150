@use '..' as able;

.able-Sequence {
  &--Default {
    &--Normal {
      @include able.Sequence(Default, Normal);
    }

    &--Start {
      @include able.Sequence(Default, Start);
    }

    &--End {
      @include able.Sequence(Default, End);
    }
  }

  &--Full {
    &--Normal {
      @include able.Sequence(Full, Normal);
    }

    &--Start {
      @include able.Sequence(Full, Start);
    }

    &--End {
      @include able.Sequence(Full, End);
    }
  }

  &--Form {
    &--Normal {
      @include able.Sequence(Form, Normal);
    }

    &--Start {
      @include able.Sequence(Form, Start);
    }

    &--End {
      @include able.Sequence(Form, End);
    }
  }
}
