@use '..' as able;

.able-PageStepper {
  @include able.PageStepper--Defaults;

  &--no-top-spacing {
    @include able.PageStepper--Defaults($top-spacing: false);

    &--ten-col-grid {
      @include able.PageStepper--Defaults($top-spacing: false, $ten-column-grid: true);
    }
  }

  &--no-bottom-spacing {
    @include able.PageStepper--Defaults($bottom-spacing-with-divider: false);

    &--ten-col-grid {
      @include able.PageStepper--Defaults($bottom-spacing-with-divider: false, $ten-column-grid: true);
    }
  }

  &--no-top-spacing--no-bottom-spacing {
    @include able.PageStepper--Defaults($top-spacing: false, $bottom-spacing-with-divider: false);

    &--ten-col-grid {
      @include able.PageStepper--Defaults($top-spacing: false, $bottom-spacing-with-divider: false, $ten-column-grid: true);
    }
  }

  &--ten-col-grid {
    @include able.PageStepper--Defaults($ten-column-grid: true);
  }
}

.able-PageStepper--Fixed {
  @include able.PageStepper--Fixed;
}

.able-PageStepper--Fluid {
  @include able.PageStepper--Fluid;
}
