.able-text-style--HeadingDisplay.Page-title, .Page-title {
  font-size: 40px !important;
  margin-top: 2.5rem;
}

.Page-subtitle {
  padding-bottom: 10px !important;
  margin-top: 32px !important;
}

.Paypal-Label {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 24px;
  padding-top: 15px;
  padding-bottom: 20px;
}

.total-to-pay {
  height: 24px;
  font-size: 24px !important;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22.4px;
}

.total-amount {
  height: 33.6px !important;
  font-size: 32px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 33.6px !important;
  text-align: right !important;
}

.phone-number {
  padding-left: 0 !important;
}

@media screen and (max-width: 48rem) {
  .Page-title {
    font-size: 32px !important;
    margin-top: 2rem;
  }
}

