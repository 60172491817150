.st0 {
  fill: #FFFFFF;
}

.st1 {
  fill: #F4F4F4;
}

.st2 {
  fill: none;
  stroke: #F4F4F4;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.st3 {
  fill: none;
  stroke: #C8C8C8;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.st4 {
  fill: #FFFFFF;
  stroke: #C8C8C8;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.st5 {
  fill: #FFFFFF;
  stroke: #F4F4F4;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.st6 {
  fill: #F4F4F4;
  stroke: #F4F4F4;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.st7 {
  fill: none;
}

.st8 {
  opacity: 0.5;
  fill: #F96449;
  enable-background: new;
}

.st9 {
  fill: #FFFFFF;
  stroke: #F96449;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.st10 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #FFDDD1;
}

.st11 {
  fill: none;
  stroke: #F96449;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.st12 {
  fill: none;
  stroke: #FFDDD1;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.st13 {
  fill: #F96449;
}

.st14 {
  fill: #0D54FF;
}

.st15 {
  fill: #FFDDD1;
}

.st16 {
  fill: #FFFFFF;
  stroke: #0D54FF;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.st17 {
  fill: #B2E2FB;
}

.st18 {
  fill: none;
  stroke: #0D54FF;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.st19 {
  fill: none;
  stroke: #B2E2FB;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.st20 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #B2E2FB;
}

.Voucher--grid__container_image_mobile .mt-illustration.mt-illustration--small {
  display: inline;
}

